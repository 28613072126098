import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { formatNumber } from "_common/utils/formatValue";
import { Link } from "react-router-dom";

import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faGolfBall } from "@fortawesome/free-solid-svg-icons";
import { VnIndexApi } from "states/api/vnIndex";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";

const PriceIndex = observer(() => {
  const {
    authStore: { user_wallet },
  } = useStores();

  const { data, refetch } = useQuery(
    ["index", "price"],
    () =>
      VnIndexApi.getList({
        params: {},
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="price-pane">
      {data?.map((item) => (
        <div className="item" key={item?.id}>
          <h4>{item?.name}</h4>
          <h5
            className={classNames({
              "text-red": item?.cIndex <= item?.oIndex,
              "text-green": item?.cIndex > item?.oIndex,
            })}
          >
            {formatNumber(item?.cIndex, "0,0.[00]")}
          </h5>
          <h5
            className={classNames({
              "text-red": item?.cIndex <= item?.oIndex,
              "text-green": item?.cIndex > item?.oIndex,
            })}
          >
            {formatNumber(item?.cIndex, "0,0.[00]")}
          </h5>
        </div>
      ))}
    </div>
  );
});

export default PriceIndex;
