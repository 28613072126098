import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";

import imgEmpty from "assets/img/empty-dark.png";
import { GamePlayerApi } from "states/api/gamePlayer";
import moment from "moment";
import { formatNumber } from "_common/utils/formatValue";
import {
  gameBetSpotStatus,
  gameBetSpotType,
} from "_common/constants/statusType";
import classNames from "classnames";
import icon19 from "assets/img/icon/icon19.png";
import icon20 from "assets/img/icon/icon20.png";

const HistorySpot = observer(() => {
  const {
    authStore: { games, logo },
  } = useStores();

  const [status, setStatus] = useState("complete");
  const { data, refetch } = useQuery(
    ["game-player", "list", status],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 50,
          status,
          type: "spot",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="market-detail">
      <div
        className="home-top-bar"
        style={{ height: 180, position: "relative", marginTop: -48, zIndex: 9 }}
      >
        <div
          className="logo-div"
          style={{ width: "100%", justifyContent: "center", marginTop: -80 }}
        >
          <img className="logo-img" src={logo} alt="" />
        </div>
      </div>
      <div className="tablist-home">
        <ul className="nav ulTab">
          <li
            className={classNames("nav-item", {
              active: status === "complete",
            })}
          >
            <img src={icon19} alt="" />
            <h5>Vị thế</h5>
          </li>
          <li
            className={classNames("nav-item", {
              active: status === "histories",
            })}
          >
            <img src={icon20} alt="" />
            <h5>Lịch sử khớp lệnh</h5>
          </li>
        </ul>
      </div>
      <div className="order-main">
        <div className="table-box">
          <table className="table">
            <thead>
              <tr className="fccs">
                <td>Mã CP</td>
                <td>Giá vốn</td>
                <td>Giá TT</td>
                <td>KL</td>
                <td>Lỗ/Lãi</td>
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((item) => (
                <tr className=" f12" key={item?.id}>
                  <td className="fck f14">{item?.stock}</td>
                  <td className="fck f14">
                    {formatNumber(item?.open, "0,0.[0000]")}
                  </td>
                  <td className="fck f14">
                    {formatNumber(item?.open, "0,0.[0000]")}
                  </td>
                  <td className="fck f14">
                    {formatNumber(item?.amount, "0,0.[0000]")}
                  </td>
                  <td className="fck f14">{gameBetSpotStatus[item?.status]}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {!data?.data?.length && (
            <div className="table-history-more">
              <img src={imgEmpty} alt="" className="empty-svg inline" />
              <p className="fccs">Chưa có giao dịch</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default HistorySpot;
