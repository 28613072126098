import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { formatNumber } from "_common/utils/formatValue";
import { Link, useNavigate } from "react-router-dom";

import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faGolfBall } from "@fortawesome/free-solid-svg-icons";
import { VnIndexApi } from "states/api/vnIndex";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import NoData from "_common/component/NoData";

const PriceExchange = observer(() => {
  const {
    authStore: { user_wallet },
  } = useStores();
  const navigate = useNavigate();

  const [typeExchange, setTypeExchange] = useState("all");

  const { data, refetch: getPrice } = useQuery(
    ["index", "exchange", typeExchange],
    () =>
      VnIndexApi.getExchange({
        params: {
          exchange: typeExchange,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [typeExchange]);

  const renderBody = useMemo(() => {
    if (!data?.length)
      return (
        <tbody>
          <tr>
            <td colSpan={4}>
              <NoData description="Không có dữ liệu" />
            </td>
          </tr>
        </tbody>
      );
    return (
      <tbody>
        {data?.map((item) => (
          <tr key={item?.id}>
            <td
              onClick={() =>
                navigate(
                  `/market?symbol=${item?.sym}&exchange=${item?.exchange}`
                )
              }
              role="presentation"
            >
              {item?.sym}
            </td>
            <td
              className={classNames({
                "text-red": item?.lastPrice <= item?.c,
                "text-green": item?.lastPrice > item?.c,
              })}
            >
              {formatNumber(item?.lastPrice)}
            </td>
            <td
              className={classNames({
                "text-red": item?.lastPrice <= item?.c,
                "text-green": item?.lastPrice > item?.c,
              })}
            >
              <p>{formatNumber(item?.ot)}</p>
              <p>{formatNumber(item?.changePc, "0,0.[00]")}%</p>
            </td>
            <td>
              <span
                className={classNames("btnFollow", {
                  "text-blue": item?.follow === 0,
                  "text-green": item?.follow === 1,
                })}
              >
                {item?.follow === 1 ? "Đang theo dõi" : "Theo dõi"}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }, [data]);

  return (
    <div className="price-top">
      <h4>Xếp hạng cổ phiếu</h4>
      <div className="tabs">
        <div
          className={classNames("tab", {
            active: typeExchange === "all",
          })}
          onClick={() => setTypeExchange("all")}
        >
          All
        </div>
        <div
          className={classNames("tab", {
            active: typeExchange === "HNX",
          })}
          onClick={() => setTypeExchange("HNX")}
        >
          HNX
        </div>
        <div
          className={classNames("tab", {
            active: typeExchange === "HOSE",
          })}
          onClick={() => setTypeExchange("HOSE")}
        >
          HOSE
        </div>
        <div
          className={classNames("tab", {
            active: typeExchange === "UPCOM",
          })}
          onClick={() => setTypeExchange("UPCOM")}
        >
          UPCOM
        </div>
      </div>
      <div className="tablle-cp">
        <table>
          <thead>
            <tr>
              <th>Chứng khoán</th>
              <th>Giá mới</th>
              <th>Tăng</th>
              <th>Theo dõi</th>
            </tr>
          </thead>
          {renderBody}
        </table>
      </div>
    </div>
  );
});

export default PriceExchange;
