import { endpointTrading } from "_common/constants/common";
import api from "states/drivers";

const VnIndexEndpoint = `${endpointTrading}/vnindex`;

export const VnIndexApi = {
  getList: async ({ params }) =>
    await api
      .get(`${VnIndexEndpoint}/getvnindex`, params)
      .then((result) => result.data?.data),
  getExchange: async ({ params }) =>
    await api
      .get(`${VnIndexEndpoint}/getstockbyexchange`, params)
      .then((result) => result.data?.data),
  getAllStock: async ({ params }) =>
    await api
      .get(`${VnIndexEndpoint}/getallstock`, params)
      .then((result) => result.data?.data),
  getStock: async ({ params }) =>
    await api
      .get(`${VnIndexEndpoint}/getstock`, params)
      .then((result) => result.data?.data),
};
