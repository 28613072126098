import React, { useEffect, useState } from "react";
import { get } from "lodash";

import { useStores } from "_common/hooks";
import classNames from "classnames";
import { GameApi } from "states/api/game";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import icon21 from "assets/img/icon/icon21.png";
import icon22 from "assets/img/icon/icon22.png";
import icon23 from "assets/img/icon/icon23.png";
import icon24 from "assets/img/icon/icon24.png";
import icon25 from "assets/img/icon/icon25.png";
import icon26 from "assets/img/icon/icon26.png";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";

function AccountAfterPage() {
  const {
    authStore: { games },
  } = useStores();

  const [activeTab, setActiveTab] = useState(get(games[0], "room_id"));

  return (
    <div className="account-page account-detail" style={{ paddingTop: 50 }}>
      <HeaderTitle title="Tạo một số giao dịch" />
      <div className="container">
        <p className="title-center">Chọn loại tài khoản giao dịch</p>
        <ul className="list-fund">
          <li className="item">
            <img src={icon22} alt="" />
            <div className="info">
              <h5>Hàng ngày</h5>
              <p>Tự động gia hạn | Lãi suất thấp chỉ 0.075%</p>
            </div>
            <FontAwesomeIcon icon={faAngleRight} />
          </li>
          <li className="item">
            <img src={icon21} alt="" />
            <div className="info">
              <h5>Miễn phí</h5>
              <p>
                Nền tảng miễn phí và cung cấp đòn bẩy cho bạn đến 10 lần để bạn
                giao dịch.
              </p>
            </div>
            <FontAwesomeIcon icon={faAngleRight} />
          </li>
          <li className="item">
            <img src={icon23} alt="" />
            <div className="info">
              <h5>Hàng tuần</h5>
              <p>Tự động gia hạn | Lãi suất thấp chỉ 0.3% </p>
            </div>
            <FontAwesomeIcon icon={faAngleRight} />
          </li>
          <li className="item">
            <img src={icon24} alt="" />
            <div className="info">
              <h5>Hàng tháng</h5>
              <p>Tự động gia hạn | Lãi suất thấp chỉ 1.05%</p>
            </div>
            <FontAwesomeIcon icon={faAngleRight} />
          </li>
          <li className="item">
            <img src={icon25} alt="" />
            <div className="info">
              <h5>Miễn lãi</h5>
              <p>Miễn phí giao dịch và không thể gia hạn</p>
            </div>
            <FontAwesomeIcon icon={faAngleRight} />
          </li>
          <li className="item">
            <img src={icon26} alt="" />
            <div className="info">
              <h5>VIP</h5>
              <p>Tự động gia hạn | Lãi suất thấp chỉ 1%</p>
            </div>
            <FontAwesomeIcon icon={faAngleRight} />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AccountAfterPage;
