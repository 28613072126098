import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import InputNumber from "_common/component/InputNumber";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";

function MarketBoard({ game_id, symbol, dataPrice }) {
  const queryClient = useQueryClient();
  const {
    authStore: { user, loading, setLoading },
  } = useStores();
  const { pushNotify, dataGame, betGame } = useNotify();
  const [bet_type, setBetType] = useState("buy");
  const [amount, setAmount] = useState(100);
  const [price, setPrice] = useState(dataPrice?.lastPrice);

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      GameApi.buyCoin({
        params: {
          game_id,
          ...variables,
        },
      }),
    {
      onSuccess: (res, variables) => {
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["game-spot", "spot"]);
        NotificationManager.success(res?.msg || "Giao dịch thành công");
        setLoading(false);

        pushNotify({
          type: "betGame",
          status: "new_bet",
        });
      },
      onError: (error) => {
        setLoading(false);
        const errorMessage =
          error?.message ?? "Xảy ra lỗi khi giao dịch thành công";
        NotificationManager.error(errorMessage);
      },
    }
  );

  useEffect(() => {
    setPrice(dataPrice?.lastPrice);
  }, [dataPrice?.lastPrice]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (loading) return;
      if (!amount) throw new Error("Vui lòng nhập số lượng giao dịch");
      if (!bet_type) throw new Error("Vui lòng chọn loại giao dịch");
      if (!game_id) throw new Error("Vui lòng chọn mã cổ phiếu");

      onSubmit({
        symbol,
        amount,
        profit: amount,
        type: "spot",
        bet_game: bet_type,
        price: dataGame?.lastPrice,
      });
    } catch (error) {
      NotificationManager.error(error?.message);
      setLoading(false);
    }
  };

  return (
    <div className="market-board">
      <h5 className="tit">Đặt lệnh:</h5>
      <div className="btn-group">
        <button
          type="button"
          onClick={() => setBetType("buy")}
          className={classNames("btn", {
            "btn-buy": bet_type === "buy",
          })}
        >
          Mua
        </button>
        <button
          type="button"
          onClick={() => setBetType("sell")}
          className={classNames("btn", {
            "btn-sell": bet_type === "sell",
          })}
        >
          Bán
        </button>
        <select>
          <option>Thường</option>
          <option>Đặt</option>
        </select>
      </div>
      <div className="row">
        <div className="col-3">
          <p>Giá:</p>
        </div>
        <div className="col-9">
          <InputNumber value={price} onChange={(val) => setPrice(val)} />
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <p>Khối lượng:</p>
        </div>
        <div className="col-9">
          <InputNumber value={amount} onChange={(val) => setAmount(val)} />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <p>K.L Khả dụng:</p>
        </div>
        <div className="col-6">
          <p style={{ justifyContent: "flex-end" }}>
            {formatNumber(user?.balance)}
          </p>
        </div>
      </div>
      <div className="market-footer">
        <button
          type="button"
          onClick={() => handleSubmit()}
          className={classNames("btn", {
            "btn-sell": bet_type === "sell",
            "btn-buy": bet_type === "buy",
          })}
        >
          Giao dịch
        </button>
      </div>
    </div>
  );
}

export default MarketBoard;
