import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { formatNumber, formatStock } from "_common/utils/formatValue";
import { useNotify } from "_common/component/NotifyProvider";

export default function HistoryPrice({ dataPrice }) {
  const {
    authStore: { trading_view },
  } = useStores();

  const { dataGame } = useNotify();

  function genRand(min, max) {
    return Math.random() * (max - min) + min;
  }

  const listBuy = useMemo(() => {
    const result = [];
    const beginPrice = Number(dataGame?.lastPrice);
    const alpha = beginPrice * 0.002;

    for (let index = 0; index < 3; index += 1) {
      result.push({
        price: beginPrice + index * alpha,
        volume: genRand(100, 10000),
        line: genRand(10, 90),
      });
    }

    return result;
  }, [dataGame]);

  const listSell = useMemo(() => {
    const result = [];
    const beginPrice = Number(dataGame?.lastPrice);
    const alpha = beginPrice * 0.002;

    for (let index = 0; index < 3; index += 1) {
      result.push({
        price: beginPrice - index * alpha,
        volume: genRand(100, 10000),
        line: genRand(10, 90),
      });
    }

    return result;
  }, [dataGame]);

  return (
    <div className="price-history">
      <div className="buy">
        {listBuy.reverse().map((item, index) => (
          <div className="item " key={`buy-${index.toString()}`}>
            <div className="wrapper">
              <div>
                <p className="text-left">{formatStock(item.price)}</p>
              </div>
              <div className="volume">
                <p className="text-left">{formatNumber(item.volume)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="sell">
        {listSell.map((item, index) => (
          <div className="item" key={`sell-${index.toString()}`}>
            <div className="wrapper">
              <div className="volume">
                <p className="!text-[#000000] text-left">
                  {formatNumber(item.volume)}
                </p>
              </div>
              <div>
                <p className="text-right">{formatStock(item.price)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
