import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { get } from "lodash";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useStores } from "_common/hooks";
import { formatNumber, formatStock } from "_common/utils/formatValue";
import HeaderDefault from "_common/component/Layout/Header/HeaderDefault";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { VnIndexApi } from "states/api/vnIndex";
import { useNotify } from "_common/component/NotifyProvider";
import InputNumber from "_common/component/InputNumber";
import iconMarket from "assets/img/icon/market_chart.png";
import HistoryPrice from "./HistoryPrice";
import MarketBoard from "./MarketBoard";

function MarketPage() {
  const {
    authStore: { user, games },
  } = useStores();

  const { pushNotify, dataGame, betGame } = useNotify();
  const [params] = useSearchParams();
  const { updatePrice } = useNotify();

  const symbol = params?.get("symbol") || "POM";
  const exchange = params?.get("exchange") || "HOSE";
  const [game_id, setGameId] = useState(games[0]?.code);

  const { data: listStock } = useQuery(
    ["stock", "all"],
    () =>
      VnIndexApi.getAllStock({
        params: {},
      }),
    {
      staleTime: 300000,
    }
  );

  const { data: dataPrice, refetch: getPrice } = useQuery(
    ["prices", "stock", symbol, exchange],
    () =>
      VnIndexApi.getStock({
        params: {
          symbol,
          exchange,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const stockInfo = useMemo(
    () => listStock?.find((obj) => obj?.sym === symbol),
    [listStock, symbol]
  );

  useEffect(() => {
    if (!dataPrice?.lastPrice) return;
    updatePrice({
      user_id: user?.id,
      lastPrice: dataPrice?.lastPrice,
    });
  }, [dataPrice?.lastPrice, user]);

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [symbol, exchange]);

  useEffect(() => {
    setGameId(symbol);
  }, [symbol]);

  return (
    <>
      <HeaderDefault />
      <div className="market-page">
        <div className="room_header">
          <div className="room-search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="form-control"
              placeholder="Nhập tên cổ phiếu/mã cổ phiếu"
            />
          </div>
          <div className="single-line">
            <h2 className="stockName">
              {dataPrice?.sym}
              <img src={iconMarket} alt="" />
            </h2>
            <h5 className="stockDes">{stockInfo?.name}</h5>
            <div className="stockPrice">
              <div className="col1">
                <h3>{formatNumber(dataGame?.lastPrice)}</h3>
                <div className="changePC">
                  <span className="text-green">
                    {formatNumber(dataPrice?.changePc, "0,0.[00]")}%
                  </span>
                  <span className="text-green">
                    {formatStock(dataPrice?.ot)}
                  </span>
                </div>
              </div>
              <div className="col1">
                <p>Khối lượng</p>
                <p>{formatNumber(dataPrice?.volume)}</p>
              </div>
            </div>
            <div className="stockInfo">
              <div className="item">
                <p>Giá trần</p>
                <h5 style={{ color: "purple" }}>
                  {formatStock(dataPrice?.highPrice)}
                </h5>
              </div>
              <div className="item">
                <p>Giá tc</p>
                <h5 style={{ color: "orange" }}>
                  {formatStock(dataPrice?.avePrice)}
                </h5>
              </div>
              <div className="item">
                <p>Giá sàn</p>
                <h5 style={{ color: "#0083ff" }}>
                  {formatStock(dataPrice?.lowPrice)}
                </h5>
              </div>
            </div>
            <div className="stockInfo2">
              <div className="item">
                <span>Mở cửa/TB</span>
                <span>
                  <span className="text-red">{formatStock(dataPrice?.o)}</span>/
                  {formatStock(dataPrice?.avePrice)}
                </span>
              </div>
              <div className="item">
                <span>Cao/Thấp</span>
                <span>
                  <span className="text-green">
                    {formatStock(dataPrice?.highPrice)}
                  </span>
                  /
                  <span className="text-green">
                    {formatStock(dataPrice?.lowPrice)}
                  </span>
                </span>
              </div>
            </div>
            <HistoryPrice dataPrice={dataPrice} />
            <div className="flex-tit">
              <span className="text-green">Mua</span>
              <span className="text-red">Bán</span>
            </div>
            <div className="buy-progress">
              <div className="percentBuy" style={{ width: "36%" }}>
                36%
              </div>
              <div className="percentSell" style={{ width: "64%" }}>
                64%
              </div>
            </div>
          </div>
          <MarketBoard
            game_id={game_id}
            symbol={symbol}
            dataPrice={dataPrice}
          />
        </div>
      </div>
    </>
  );
}

export default MarketPage;
