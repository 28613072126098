import React, { useState } from "react";
import { useStores } from "_common/hooks";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import PageLoading from "_common/component/PageLoading";
import { observer } from "mobx-react-lite";
import MobileLayout from "./MobileLayout";

const BasicLayout = observer(() => {
  const { authStore } = useStores();

  if (!authStore.token) return <PageLoading />;
  if (!authStore.user?.id) return <PageLoading />;

  return <MobileLayout />;
});

export default BasicLayout;
