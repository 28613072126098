import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { BankApi } from "states/api/bank";
import { get } from "lodash";
import { NotificationManager } from "react-notifications";

const BankPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  const { data, refetch } = useQuery(
    ["bank", "list"],
    () =>
      BankApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const { mutate, isLoading } = useMutation(
    (id) => BankApi.delete({ params: { ids: [id] } }),
    {
      onSuccess: (res) => {
        refetch();
        NotificationManager.success(res?.msg || "Thao tác thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <HeaderTitle title="Quản lý ngân hàng" />

      <div className="payment-container bank-page">
        <div className="list_content">
          {get(data, "data", [])?.map((item) => (
            <div
              className="item"
              key={item?.id}
              onClick={() => mutate(item?.id)}
            >
              <h2>{get(item, "bank_name")}</h2>
              <h3>{get(item, "bank_owner")}</h3>
              <h3>VND</h3>
              <h3>{get(item, "bank_number_display")}</h3>
              <button type="button" className="btn-delete">
                Xóa
              </button>
            </div>
          ))}
        </div>
        <Link to="/payment/bank/add" className="add-card">
          <FontAwesomeIcon icon={faPlus} />
          <span>Thêm thẻ</span>
        </Link>
      </div>
    </>
  );
});

export default BankPage;
