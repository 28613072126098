import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";

function HeaderDefault({ ...props }) {
  const {
    authStore: { logo },
  } = useStores();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (
    <div className="home-top-bar" {...props}>
      <div
        className="logo-div"
        style={{ width: "100%", justifyContent: "center" }}
      >
        <img className="logo-img" src={logo} alt="" />
      </div>
    </div>
  );
}

export default HeaderDefault;
