import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";

function InputNumber({ value, onChange, ...props }, ref) {
  const inputRef = React.useRef(null);

  React.useImperativeHandle(ref, () => inputRef.current);

  const [amount, setAmount] = useState(value);

  const handleChangeValue = (val) => {
    onChange(val);
    setAmount(val);
  };

  useEffect(() => {
    setAmount(value);
  }, [value]);

  return (
    <div
      className="qty-input"
      style={{
        width: "100%",
      }}
    >
      <button
        type="button"
        className="btn btn-add"
        style={{
          width: 64,
        }}
        onClick={() => handleChangeValue(amount >= 50 ? amount - 50 : 0)}
      >
        <FontAwesomeIcon icon={faMinus} />
      </button>
      <input
        type="number"
        value={amount}
        onChange={(e) => {
          handleChangeValue(e?.target?.value);
        }}
        className="sbui-input sbui-input--large"
      />
      <button
        type="button"
        className="btn btn-add"
        style={{
          width: 64,
        }}
        onClick={() => handleChangeValue(amount + 50)}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  );
}

export default React.forwardRef(InputNumber);
