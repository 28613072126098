import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import imgCSKH from "assets/img/icon/cskh.png";
import imgLogout from "assets/img/icon/logout.png";

function HeaderTop({ isBack = true, title }) {
  const {
    authStore: { logo },
  } = useStores();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return (
    <div className="home-top-bar">
      <div className="logo-div">
        <img className="logo-img" src={logo} alt="" />
      </div>
      <div className="server-msg-div">
        <div>
          <img src={imgCSKH} alt="" />
        </div>
        <div>
          <img src={imgLogout} alt="" />
        </div>
      </div>
    </div>
  );
}

export default HeaderTop;
