import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { useStores } from "_common/hooks";
import classNames from "classnames";
import InputPassword from "_common/component/InputPassword";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faPhone,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import enImg from "assets/img/icon/en.png";

const schema = yup
  .object({
    username: yup
      .string()
      .min(6, "Vui lòng nhập tên người dùng lớn hơn 6 ký tự")
      .required("Vui lòng nhập tên người dùng"),
    password: yup.string().required("Vui lòng nhập mật khẩu"),
    ref_no: yup.string().required("Vui lòng nhập mã mời"),
  })
  .required();

export default function RegisterForm() {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.register(variables),
    {
      onSuccess: (res) => {
        authStore.updateToken(res?.data);
        authStore.updateUser(res?.data);
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRegister = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <form className="auth-form-user" onSubmit={handleSubmit(handleRegister)}>
      <div>
        <ul>
          <li
            className={classNames("marginBottom", {
              error: errors.username,
            })}
          >
            <div className="form_In">
              <div className="field-group">
                <FontAwesomeIcon icon={faPhone} />
                <input
                  className="field_control"
                  id="username"
                  type="text"
                  placeholder="Vui lòng nhập số điện thoại"
                  {...register("username")}
                />
              </div>
              <span className="error_t">{errors.username?.message}</span>
            </div>
          </li>
          <li
            className={classNames("marginBottom", {
              error: errors.password,
            })}
          >
            <div className="form_In">
              <div className="field-group">
                <FontAwesomeIcon icon={faLock} />
                <InputPassword
                  className="field_control"
                  id="password"
                  placeholder="Vui lòng nhập mật khẩu, ít nhất 8 ký tự"
                  {...register("password")}
                />
              </div>
              <span className="error_t">{errors.password?.message}</span>
            </div>
          </li>
          <li
            className={classNames("marginBottom", {
              error: errors.confirmed,
            })}
          >
            <div className="form_In">
              <div className="field-group">
                <FontAwesomeIcon icon={faLock} />
                <InputPassword
                  className="field_control"
                  id="password"
                  placeholder="Vui lòng xác nhận mật khẩu"
                  {...register("password")}
                />
              </div>
              <span className="error_t">{errors.confirmed?.message}</span>
            </div>
          </li>
          <li
            className={classNames("marginBottom", {
              error: errors.ref_no,
            })}
          >
            <div className="form_In">
              <div className="field-group">
                <FontAwesomeIcon icon={faUser} />
                <input
                  className="field_control"
                  id="ref_no"
                  placeholder="Vui lòng nhập mã mời"
                  {...register("ref_no")}
                />
              </div>
              <span className="error_t">{errors.ref_no?.message}</span>
            </div>
          </li>
        </ul>
        <div className="form_button">
          <button type="submit" className="btn btn-submit">
            Đăng ký
          </button>
        </div>
        <p className="signin-other-title">
          <Link to="/auth/login">Đăng nhập</Link>
        </p>
        <div className="login-footer">
          {/* <Link to="/language" className="lang">
            <img src={enImg} alt="" />
            <span>Ngôn ngữ</span>
          </Link> */}
          <Link className="btn-support" to="/support">
            Hỗ trợ
          </Link>
        </div>
      </div>
    </form>
  );
}
