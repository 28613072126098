import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { ProfileApi } from "states/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import classNames from "classnames";

const typeText = {
  full_name: "Sửa đổi họ tên thật",
  identify_number: "Sửa đổi họ tên thật",
};

const schema = yup
  .object({
    full_name: yup.string().required("Bắt buộc điền"),
    identify_number: yup.string().required("Bắt buộc điền"),
  })
  .required();

const UpdateInfoPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet },
  } = useStores();
  const [params] = useSearchParams();
  const type = params.get("type");

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.update({ params: variables }),
    {
      onSuccess: (res) => {
        navigate("/account");
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(res?.msg || "Thao tác thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = () => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      mutate(formValue);
    }
  };

  useEffect(() => {
    if (!user) return;
    setValue("full_name", user?.full_name);
    setValue("identify_number", user?.identify_number);
  }, [user]);

  return (
    <>
      <HeaderTitle
        title={typeText[type]}
        isSave
        onSubmit={handleSubmit(onSubmit)}
      />
      <div className="update-info-container">
        <form className="form-action form-horizontal">
          <div
            className={classNames("form-item", {
              error: errors.full_name,
            })}
          >
            <div htmlFor="full_name" className="form_label">
              Họ tên
            </div>
            <div className="form_field">
              <input
                className="field_control"
                id="full_name"
                type="text"
                placeholder="Vui lòng nhập tài khoản"
                {...register("full_name")}
              />
              <span className="error_t">{errors.full_name?.message}</span>
            </div>
          </div>
          <div
            className={classNames("form-item", {
              error: errors.identify_number,
            })}
          >
            <div htmlFor="identify_number" className="form_label">
              Số CMND/CCCD
            </div>
            <div className="form_field">
              <input
                className="field_control"
                id="identify_number"
                type="text"
                placeholder="Vui lòng nhập số CMND/CCCD"
                {...register("identify_number")}
              />

              <span className="error_t">{errors.identify_number?.message}</span>
            </div>
          </div>
        </form>
      </div>
    </>
  );
});

export default UpdateInfoPage;
