import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { useStores } from "_common/hooks";

import DepositIcon from "assets/icons/DepositIcon";
import HomeIcon from "assets/icons/HomeIcon";
import SupportIcon from "assets/icons/SupportIcon";
import UserIcon from "assets/icons/UserIcon";
import iHome from "assets/img/icon/ihome.png";
import iHomeActive from "assets/img/icon/ihome_active.png";
import iTran from "assets/img/icon/itran.png";
import iTranActive from "assets/img/icon/itran_active.png";
import iWallet from "assets/img/icon/iwallet.png";
import iWalletActive from "assets/img/icon/iwallet_active.png";
import iSupport from "assets/img/icon/isupport.png";
import iSupportActive from "assets/img/icon/isupport_active.png";
import iAccount from "assets/img/icon/iaccount.png";
import iAccountActive from "assets/img/icon/iaccount_active.png";

export default function MobileFooter({ toggleMenu, toggleCollapsed, menus }) {
  const {
    authStore: { user, user_wallet },
  } = useStores();
  const location = useLocation();
  const pathName = location?.pathname;

  return (
    <div className="footer-mobile">
      <Link
        to="/"
        className={classNames("footer_item", {
          active: pathName === "/",
        })}
      >
        {pathName === "/" ? (
          <img src={iHomeActive} alt="" />
        ) : (
          <img src={iHome} alt="" />
        )}
        <h5>Trang</h5>
      </Link>
      <Link
        to="/market"
        className={classNames("footer_item", {
          active: pathName === "/market",
        })}
      >
        {pathName === "/market" ? (
          <img src={iTranActive} alt="" />
        ) : (
          <img src={iTran} alt="" />
        )}
        <h5>Giao dịch</h5>
      </Link>
      <Link
        to="/market/histories"
        className={classNames("footer_item", {
          active: pathName === "/market/histories",
        })}
      >
        {pathName === "/market/histories" ? (
          <img src={iWalletActive} alt="" />
        ) : (
          <img src={iWallet} alt="" />
        )}
        <h5>Danh mục</h5>
      </Link>
      <Link
        to="/account"
        className={classNames("footer_item", {
          active: pathName === "/account",
        })}
      >
        {pathName === "/account" ? (
          <img src={iSupportActive} alt="" />
        ) : (
          <img src={iSupport} alt="" />
        )}
        <h5>Tài khoản</h5>
      </Link>
      <Link
        to="/member"
        className={classNames("footer_item", {
          active: pathName === "/member",
        })}
      >
        {pathName === "/member" ? (
          <img src={iAccountActive} alt="" />
        ) : (
          <img src={iAccount} alt="" />
        )}
        <h5>Cá nhân</h5>
      </Link>
    </div>
  );
}
