import React, { useEffect, useState } from "react";
import { get } from "lodash";

import { useStores } from "_common/hooks";
import classNames from "classnames";
import { GameApi } from "states/api/game";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import iconFree from "assets/img/icon/icon21.png";
import icon27 from "assets/img/icon/icon27.png";
import icon28 from "assets/img/icon/icon28.png";
import { Link, useNavigate } from "react-router-dom";

function AccountPage() {
  const navigate = useNavigate();
  const {
    authStore: { games },
  } = useStores();

  const [activeTab, setActiveTab] = useState(get(games[0], "room_id"));

  return (
    <div className="account-page">
      <div className="game-navbar">
        <b>Danh sách hợp đồng</b>
      </div>
      <div className="container">
        <Link to="/account/after" className="btn-request">
          <span>Yêu cầu hợp đồng cho bạn</span>
          <div className="rit">
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </Link>
        <h5 className="fee-tit ">Phí quản lý: 0</h5>
        <p className="fee-des">
          (Phi quản lý là phí có thể sử dụng thay thế tiền để gia hạn hoặc thanh
          toán hợp đồng)
        </p>
        <ul className="list-fund">
          <li className="item">
            <img src={iconFree} alt="" />
            <div className="info">
              <h5>Hợp đồng: Trải nghiệm</h5>
              <p>Số tiền: 10,000,000 </p>
            </div>
            <FontAwesomeIcon icon={faAngleRight} />
          </li>
          <li
            className="item"
            role="presentation"
            onClick={() => navigate("/member/update-info")}
          >
            <img src={icon27} alt="" />
            <div className="info">
              <h5>Xác minh tài khoản</h5>
              <p>Chưa xác minh</p>
            </div>
            <FontAwesomeIcon icon={faAngleRight} />
          </li>
          <li
            className="item"
            role="presentation"
            onClick={() => navigate("/payment/bank")}
          >
            <img src={icon28} alt="" />
            <div className="info">
              <h5>Liên kết tài khoản ngân hàng</h5>
              <p>Đã liên kết 0/3 </p>
            </div>
            <FontAwesomeIcon icon={faAngleRight} />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AccountPage;
