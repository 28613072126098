import React from "react";
import { Routes, Route } from "react-router-dom";

import BasicLayout from "_common/component/Layout/BasicLayout";
import UnAuthLayout from "_common/component/Layout/UnAuthLayout";
import PageNotfound from "_common/component/PageNotfound";

// auth
import LoginPage from "pages/Auth/Login";
import RegisterPage from "pages/Auth/Register";

import { MiddlewareRouter } from "routers/MiddlewareRouter";
import CheckAuth from "routers/CheckAuth";
import HomePage from "pages/Home";
import MemberPage from "pages/Member";
import SettingPage from "pages/Member/Setting";
import InformationPage from "pages/Member/Information";
import UpdateInfoPage from "pages/Member/UpdateInfo";
import ChangePasswordPage from "pages/Member/ChangePassword";
import ChangeWalletPasswordPage from "pages/Member/ChangeWalletPassword";
import SettingGeneral from "pages/Member/SettingGeneral";
import SetLinePage from "pages/Member/SetLine";
import AboutPage from "pages/Member/About";
import ContactPage from "pages/Member/Contact";
import DepositPage from "pages/Payment/Deposit";
import DepositFormPage from "pages/Payment/Deposit/DepositFormPage";
import DepositBankInfo from "pages/Payment/Deposit/DepositBankInfo";
import DepositSuccessPage from "pages/Payment/Deposit/DepositSuccessPage";
import DepositHistoryPage from "pages/History/Deposit";
import DepositDetailPage from "pages/History/Deposit/Detail";
import WithdrawPage from "pages/Payment/Withdraw";
import BankAddPage from "pages/Payment/Bank/BankAdd";
import WithdrawDetailPage from "pages/History/Withdraw/Detail";
import WithdrawHistoryPage from "pages/History/Withdraw";
import AccountHistoryPage from "pages/History/Account";
import RoomPage from "pages/Room";
import GameHistoryPage from "pages/History/Game";
import GameHistoryDetailPage from "pages/History/Game/Detail";
import PromotionPage from "pages/Promotion";
import SupportPage from "pages/Support";
import BalanceHistoryPage from "pages/History/Balance";
import BalanceDetailPage from "pages/History/Balance/Detail";
import UnAuthBasicLayout from "_common/component/Layout/BasicLayout/UnAuthBasicLayout";
import ReportHistoryPage from "pages/History/Report";
import CommissionDetailPage from "pages/History/Commission/Detail";
import CommissionHistoryPage from "pages/History/Commission";
import GameLayout from "_common/component/Layout/GameLayout";
import ServicePage from "pages/Member/Service";
import RechargePage from "pages/Payment/Recharge";
import MarketPage from "pages/Market";
import UpdateWalletPage from "pages/Member/UpdateWallet";
import HistorySpot from "pages/Market/HistorySpot";
import AccountPage from "pages/Account";
import AccountAfterPage from "pages/Account/AccountAfter";
import BankPage from "pages/Payment/Bank";

export function Routers() {
  return (
    <Routes>
      <Route path="/" element={<UnAuthBasicLayout />}>
        <Route path="support" element={<SupportPage />} />
        <Route path="language" element={<SetLinePage />} />
      </Route>
      <Route
        path="/"
        element={
          <MiddlewareRouter>
            <BasicLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<HomePage />} />
        <Route path="home" element={<HomePage />} />
        <Route path="service" element={<ServicePage />} />
        <Route path="room" element={<RoomPage />} />
        <Route path="promotion" element={<PromotionPage />} />
        <Route path="account">
          <Route index element={<AccountPage />} />
          <Route path="after" element={<AccountAfterPage />} />
        </Route>
        <Route path="member">
          <Route index element={<MemberPage />} />
          <Route path="setting" element={<SettingPage />} />
          <Route path="information" element={<InformationPage />} />
          <Route path="update-wallet" element={<UpdateWalletPage />} />
          <Route path="update-info" element={<UpdateInfoPage />} />
          <Route path="change-password" element={<ChangePasswordPage />} />
          <Route path="setting-general" element={<SettingGeneral />} />
          <Route path="set-line" element={<SetLinePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="contact" element={<ContactPage />} />

          <Route
            path="change-wallet-password"
            element={<ChangeWalletPasswordPage />}
          />
        </Route>
        <Route path="payment">
          <Route path="deposit" element={<DepositPage />} />
          <Route path="deposit/submit" element={<DepositFormPage />} />
          <Route path="deposit/bank-info" element={<DepositBankInfo />} />
          <Route path="deposit/success" element={<DepositSuccessPage />} />
          <Route path="withdraw" element={<WithdrawPage />} />
          <Route path="recharge" element={<RechargePage />} />
          <Route path="bank" element={<BankPage />} />
          <Route path="bank/add" element={<BankAddPage />} />
        </Route>
        <Route path="histories">
          <Route path="game" element={<GameHistoryPage />} />
          <Route path="game/detail" element={<GameHistoryDetailPage />} />
          <Route path="account" element={<AccountHistoryPage />} />
          <Route path="deposit" element={<DepositHistoryPage />} />
          <Route path="deposit/detail" element={<DepositDetailPage />} />
          <Route path="withdraw" element={<WithdrawHistoryPage />} />
          <Route path="commission" element={<CommissionHistoryPage />} />
          <Route path="balance" element={<BalanceHistoryPage />} />
          <Route path="report" element={<ReportHistoryPage />} />
          <Route path="balance/detail" element={<BalanceDetailPage />} />
          <Route path="withdraw/detail" element={<WithdrawDetailPage />} />
          <Route path="commission/detail" element={<CommissionDetailPage />} />
        </Route>
      </Route>
      <Route
        path="market"
        element={
          <MiddlewareRouter>
            <GameLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<MarketPage />} />
        <Route path="histories" element={<HistorySpot />} />
      </Route>
      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
      </Route>
      <Route path="*" element={<PageNotfound />} />
    </Routes>
  );
}
