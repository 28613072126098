import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faMoneyBillTransfer,
  faWallet,
  faRotate,
  faAngleRight,
  faListAlt,
  faLock,
  faBank,
  faUserLock,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { formatCurrency, formatNumber } from "_common/utils/formatValue";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";

import cskh from "assets/img/icon/cskh.png";
import global from "assets/img/icon/global.png";
import icon9 from "assets/img/icon/icon9.png";
import icon10 from "assets/img/icon/icon10.png";
import icon11 from "assets/img/icon/icon11.png";
import icon12 from "assets/img/icon/icon12.png";
import icon13 from "assets/img/icon/icon13.png";
import icon14 from "assets/img/icon/icon14.png";
import icon15 from "assets/img/icon/icon15.png";
import icon16 from "assets/img/icon/icon16.png";
import icon17 from "assets/img/icon/icon17.png";
import icon18 from "assets/img/icon/icon18.png";

const MemberPage = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, logo, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage = error?.message ?? "Vui lòng thử lại!";
      NotificationManager.error(errorMessage);
    },
  });

  const getProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className="personal-page">
      <div className="header">
        <div className="user-card">
          <div className="user-info">
            <div className="vip-icon">
              <img src={user?.avatar_url} alt="" />
            </div>
            <div className="user-option">
              <div className="user-option-item">
                <span>Tài khoản:</span>
                <span>{user?.full_name || user?.username} </span>
              </div>
              <div className="user-option-item">
                <span>SĐT:</span>
                <span>{user?.phone} </span>
              </div>
            </div>
          </div>
          <div className="user-balance">
            <span>Số dư:</span>
            <span>{formatNumber(user?.balance, "0,0.[0000]")}</span>
          </div>
          <div className="user-action">
            <div>
              <img src={global} alt="" />
            </div>
            <div>
              <img src={cskh} alt="" />
            </div>
          </div>
        </div>
        <div className="btn-logout" onClick={() => onLogout()}>
          Đăng xuất
        </div>
      </div>
      <div className="user-report">
        <div className="report">
          <div className="uni-flex-item">
            <div className="grid__name">
              <img src={icon9} alt="" />
              Tổng tài sản CP
            </div>
            <div className="grid__name__red">
              {formatNumber(user?.total_assets, "0,0.[0000]")}
            </div>
          </div>
          <div className="uni-flex-item">
            <div className="grid__name">
              <img src={icon9} alt="" />
              Đóng băng
            </div>
            <div className="grid__name__red">
              {formatNumber(user?.balance_pending, "0,0.[0000]")}
            </div>
          </div>
          <div className="uni-flex-item">
            <div className="grid__name">
              <img src={icon9} alt="" />
              Số dư
            </div>
            <div className="grid__name__red">
              {formatNumber(user?.balance, "0,0.[0000]")}
            </div>
          </div>
          <div className="uni-flex-item">
            <div className="grid__name">
              <img src={icon9} alt="" />
              Tổng dư nợ hợp đồng
            </div>
            <div className="grid__name__red">
              {formatNumber(user?.total_debit_contract, "0,0.[0000]")}
            </div>
          </div>
        </div>
        <div className="btn-group">
          <Link to="/support" className="btn btn-deposit">
            <img src={icon10} alt="" />
            <p>Nạp tiền</p>
          </Link>
          <Link to="/payment/withdraw" className="btn btn-withdraw">
            <img src={icon11} alt="" />
            <p>Rút tiền</p>
          </Link>
          <Link to="/histories/balance" className="btn btn-withdraw">
            <img src={icon15} alt="" />
            <p>Lịch sử giao dịch</p>
          </Link>
        </div>
      </div>
      <div className="container">
        <div className="cell-list" style={{ marginTop: 10 }}>
          <Link to="/member/setting" className="cell-item">
            <div className="icon">
              <img src={icon17} alt="" />
            </div>
            <div className="name">Mật khẩu giao dịch</div>
            <span className="arrow">
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
});

export default MemberPage;
