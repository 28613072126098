import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import { Link, useNavigate } from "react-router-dom";
import { formatCurrency, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { get } from "lodash";
import { PaymentApi } from "states/api/payment";

const WithdrawForm = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, banks, bank_default },
  } = useStores();

  const [amount, setAmount] = useState(0);
  const [wallet_password, setWalletPass] = useState();
  const [bank_id, setBankId] = useState(bank_default?.id);

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      PaymentApi.deposit({
        params: {
          ...variables,
          amount,
          bank_method: "banking",
          symbol: "usdt",
          token_id: 3,
          wallet_password,
          bank_id,
          type: "withdraw",
        },
      }),
    {
      onSuccess: (res) => {
        setAmount(0);
        setWalletPass("");
        queryClient.invalidateQueries(["get_profile"]);
        navigate(`/payment/deposit/success?money=${amount}`);
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  useEffect(() => {
    if (!bank_default?.id) return;
    setBankId(bank_default?.id);
  }, [bank_default?.id]);

  const handleChangeInput = (event) => {
    const value = event?.target?.value;
    const format = value?.replaceAll(",", "")?.replaceAll(".", "");
    setAmount(Number(format));
  };

  const handleSubmit = () => {
    if (!bank_id)
      return NotificationManager.error("Vui lòng chọn ngân hàng rút tiền");
    onSubmit();
    return null;
  };

  useEffect(() => {
    queryClient.invalidateQueries(["get_profile"]);
  }, []);

  return (
    <div className="withdrawal-page">
      <div className="adm-form withdrwalForm">
        <div className="adm-list adm-list-default">
          <div className="adm-list-body">
            <div className="adm-list-body-inner">
              <div className="adm-list-item adm-form-item adm-form-item-horizontal">
                <div className="adm-list-item-content">
                  <div className="adm-list-item-content-prefix">
                    <div className="label adm-form-item-label">
                      Phương thức rút tiền
                    </div>
                  </div>
                  <div className="adm-list-item-content-main">
                    <div className="adm-form-item-child adm-form-item-child-position-normal">
                      <div className="adm-form-item-child-inner">
                        <div className="adm-input">
                          <select
                            placeholder="Chọn phương thức rút tiền"
                            onChange={(e) => setBankId(e?.target?.value)}
                          >
                            {banks?.map((item) => (
                              <option
                                key={item?.id}
                                value={item?.id}
                                onClick={(e) => setBankId(item?.id)}
                              >
                                {item?.bank_name} | {item?.bank_number_display}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="adm-list-item adm-form-item adm-form-item-horizontal">
                <div className="adm-list-item-content">
                  <div className="adm-list-item-content-prefix">
                    <div className="label adm-form-item-label">Số tiền rút</div>
                  </div>
                  <div className="adm-list-item-content-main">
                    <div className="adm-form-item-child adm-form-item-child-position-normal">
                      <div className="adm-form-item-child-inner">
                        <div className="adm-input">
                          <input
                            inputMode="numeric"
                            pattern="[0-9]"
                            placeholder="Vui lòng nhập số tiền rút"
                            className="adm-input-element"
                            value={amount ? formatNumber(amount) : ""}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="adm-list-item adm-form-item adm-form-item-horizontal">
                <div className="adm-list-item-content">
                  <div className="adm-list-item-content-prefix">
                    <div className="label adm-form-item-label">Mật khẩu</div>
                  </div>
                  <div className="adm-list-item-content-main">
                    <div className="adm-form-item-child adm-form-item-child-position-normal">
                      <div className="adm-form-item-child-inner">
                        <div className="adm-input">
                          <input
                            placeholder="Vui lòng nhập mật khẩu giao dịch"
                            className="adm-input-element"
                            value={wallet_password}
                            onChange={(e) => setWalletPass(e?.target?.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="adm-list-item adm-form-item adm-form-item-horizontal">
                <div className="adm-list-item-content">
                  <div className="adm-list-item-content-prefix">
                    <div className="label adm-form-item-label">
                      Số dư {formatNumber(user?.balance, "0,0.[0000]")}
                      <span
                        className="btn-all"
                        onClick={() => setAmount(user?.balance)}
                      >
                        Rút tất cả
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="adm-list-item adm-form-item adm-form-item-horizontal">
                <div className="adm-list-item-content">
                  <button
                    type="button"
                    className={classNames("btn btn-sub uni-btn-primary")}
                    style={{ marginTop: 25 }}
                    onClick={handleSubmit}
                  >
                    Xác nhận
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default WithdrawForm;
