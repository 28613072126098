import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BankApi } from "states/api/bank";
import { optionBank } from "_common/constants/bank";
import nganhang from "assets/img/nganhang.jpg";
import { ProfileApi } from "states/api";

const schema = yup
  .object({
    bank_owner: yup.string().required("Bắt buộc điền"),
    bank_number: yup.string().required("Bắt buộc điền"),
    bank_name: yup.string().required("Bắt buộc điền"),
  })
  .required();

const BankAddPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();
  const [params] = useSearchParams();
  const id = params.get("id");

  const { data, refetch } = useQuery(
    ["bank", "detail", id],
    () =>
      BankApi.getDetail({
        id,
      }),
    {
      staleTime: 300000,
      enabled: !!id,
    }
  );

  const { mutate, isLoading } = useMutation(
    (variables) => {
      if (id) return BankApi.update({ id, params: variables });
      return BankApi.create({ params: variables });
    },
    {
      onSuccess: (res) => {
        navigate(-1);
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(res?.msg || "action_success_msg");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "action_failed_msg";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      // const bank = optionBank.find((obj) => obj.value === values?.bank_code);

      mutate({
        ...values,
        // bank_name: bank?.text,
      });
    }
  };

  useEffect(() => {
    if (!data) return;
    if (data?.bank_number) {
      setValue("bank_number", data?.bank_number_display);
      setValue("bank_name", data?.bank_name);
      setValue("bank_owner", data?.bank_owner);
    }
  }, [data]);

  const isDisable = !!data?.bank_number;

  return (
    <>
      <HeaderTitle title="Thêm thẻ ngân hàng" />

      <div className="payment-container bank-add-page">
        <form
          className="form-action form-horizontal"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            className={classNames("form-item", {
              error: errors.bank_name,
            })}
          >
            <div className="form_field">
              <input
                className="field_control"
                type="text"
                id="bank_name"
                placeholder="Nhập tên ngân hàng"
                readOnly={isDisable}
                {...register("bank_name")}
              />
              <span className="error_t">{errors.bank_name?.message}</span>
            </div>
          </div>
          <div
            className={classNames("form-item", {
              error: errors.bank_owner,
            })}
          >
            <div className="form_field">
              <input
                className="field_control"
                type="text"
                id="bank_owner"
                placeholder="Nhập họ và tên đầy đủ"
                readOnly={isDisable}
                {...register("bank_owner")}
              />
              <span className="error_t">{errors.bank_owner?.message}</span>
            </div>
          </div>
          <div
            className={classNames("form-item", {
              error: errors.bank_number,
            })}
          >
            <div className="form_field">
              <input
                className="field_control"
                type="text"
                id="bank_number"
                placeholder="Nhập số tài khoản ngân hàng"
                readOnly={isDisable}
                {...register("bank_number")}
              />
              <span className="error_t">{errors.bank_number?.message}</span>
            </div>
          </div>
          {!isDisable && (
            <button
              type="submit"
              className={classNames("btn btn-sub", {
                active: true,
              })}
            >
              Xác nhận
            </button>
          )}
        </form>
      </div>
    </>
  );
});

export default BankAddPage;
