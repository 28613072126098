import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { formatNumber } from "_common/utils/formatValue";
import { Link } from "react-router-dom";

import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faGolfBall } from "@fortawesome/free-solid-svg-icons";

import point from "assets/img/icon/point.png";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import icon1 from "assets/img/icon/icon1.png";
import icon2 from "assets/img/icon/icon2.png";
import icon3 from "assets/img/icon/icon3.png";
import icon4 from "assets/img/icon/icon4.png";
import icon5 from "assets/img/icon/icon5.png";
import icon6 from "assets/img/icon/icon6.png";
import icon7 from "assets/img/icon/icon7.png";
import icon8 from "assets/img/icon/icon8.png";

import ListBanner from "./ListBanner";
import Notice from "./Notice";
import Profit from "./Profit";
import PriceIndex from "./PriceIndex";
import PriceExchange from "./PriceExchange";

const Home = observer(() => {
  const {
    authStore: { user_wallet },
  } = useStores();

  return (
    <>
      <HeaderTop />
      <div className="page-container">
        <div className="home-page">
          <ListBanner />
          <Notice />
          <div className="row home-menu">
            <div className="col col-4">
              <Link to="/room">
                <img src={icon2} alt="" />
                <p>Giới thiệu</p>
              </Link>
            </div>
            <div className="col col-4">
              <Link to="/room">
                <img src={icon3} alt="" />
                <p>Cổ phiếu nội bộ</p>
              </Link>
            </div>
            <div className="col col-4">
              <Link to="/room">
                <img src={icon5} alt="" />
                <p>Tin tức</p>
              </Link>
            </div>
            <div className="col col-4">
              <Link to="/room">
                <img src={icon6} alt="" />
                <p>Nạp tiền</p>
              </Link>
            </div>
            <div className="col col-4">
              <Link to="/room">
                <img src={icon8} alt="" />
                <p>Dịch vụ hỗ trợ online</p>
              </Link>
            </div>
            <div className="col col-4">
              <Link to="/room">
                <img src={icon7} alt="" />
                <p>Hướng dẫn</p>
              </Link>
            </div>
          </div>
          <PriceIndex />
          <PriceExchange />
        </div>
      </div>
    </>
  );
});

export default Home;
